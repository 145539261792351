import { createPinia } from 'pinia'
import vClickOutside from 'v-click-outside'
import { createApp } from 'vue'

import App from './App.vue'
import { request } from './requests/request'
import router from './router'

const pinia = createPinia()

import '@rhsolutions/design-system/dist/assets/index.css'
require('./assets/style/global.scss')

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(vClickOutside)
app.mount('#app')

request.init()
