import { AppConfig } from '@/configs/app.config'
import { request } from '@/requests/request'

export class TokenUtilities {
  static accessToken = null
  static accessExpirationTimestamp = null
  static refreshToken = null
  static refreshExpirationTimestamp = null
  static refreshInterval = null

  static getTokens() {
    let data
    const tokens = localStorage.getItem('token')

    if (tokens) {
      data = JSON.parse(tokens)

      this.accessToken = data.access_token
      this.refreshToken = data.refresh_token
    }

    return data
  }

  static async clearTokens() {
    localStorage.removeItem('token')

    this.accessExpirationTimestamp = null
    this.refreshExpirationTimestamp = null

    if (this.refreshInterval) clearInterval(this.refreshInterval)

    window.location.href = AppConfig.redirectUrl + '/auth'
  }

  static setTokensInfo(tokens) {
    if (!tokens) return

    const access = tokens.access_token.split('.')
    const decodedAccess = JSON.parse(atob(access[1]))

    this.accessExpirationTimestamp = new Date(decodedAccess.exp * 1000)

    const refresh = tokens.refresh_token.split('.')
    const decodedRefresh = JSON.parse(atob(refresh[1]))

    this.refreshExpirationTimestamp = new Date(decodedRefresh.exp * 1000)

    this.refreshInterval = setInterval(async () => {
      const currentTimestamp = new Date()
      if (!this.accessExpirationTimestamp || !this.refreshExpirationTimestamp || this.accessExpirationTimestamp > currentTimestamp) return


      if (this.accessExpirationTimestamp <= currentTimestamp && this.refreshExpirationTimestamp <= currentTimestamp) {
        await this.clearTokens()
        clearInterval(this.refreshInterval)
      } else if (this.accessExpirationTimestamp <= currentTimestamp && this.refreshExpirationTimestamp > currentTimestamp) {
        await this.refreshTokens()
      }
    }, 300)
  }

  static async refreshTokens() {
    request.axios.post('/update-token', { refresh: this.refreshToken }, { headers: { Authorization: undefined } })
      .then((res) => {
        if (res.status !== 200) return

        this.setTokensInfo(res.data)
      })
      .catch(() => {
        this.clearTokens()
      })
  }
}
