<template>
  <router-view />
</template>

<script setup>
import { request } from '@/requests/request'
import { TokenUtilities } from '@/utilities/token.utilities'

request.init()

const tokens = TokenUtilities.getTokens()

TokenUtilities.setTokensInfo(tokens)
</script>

<style lang="scss"></style>
